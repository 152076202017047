/* eslint-disable import/prefer-default-export */

const getTerm = (slug, terms) => {
  const { node: term } = terms?.edges.find((item) => item.node.slug === slug);
  return term;
};

const getTermName = (slug, terms) => {
  const { node: term } = terms?.edges.find((item) => item.node.slug === slug);
  return term;
};

export { getTerm, getTermName };
