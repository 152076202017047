/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';
import { Element, Link as ScrollLink } from 'react-scroll';

import { useTranslation } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Button from '../components/Button';
import Image from '../components/Image';
// import TestimonialItem from '../components/TestimonialItem';

import { breakpoint, colors } from '../theme';
import GridItem from '../components/GridItem';

import { getTerm } from '../utils/helperFunctions';
import { SectionHeader, SectionFooter } from '../utils/GlobalFormatting';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto;
`;

const IntroSection = styled.div`
  padding-top: ${rem(50)};

  ${breakpoint('large')} {
    padding-top: ${rem(80)};
  }
`;

const IntroContent = styled(Col)`
  text-align: center;
  width: 100%;
  max-width: ${rem(600)};
  margin: 0 auto ${rem(28)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(820)};
    font-size: ${rem(19)};
  }
`;

const Section = styled.div`
  text-align: center;
  justify-content: center;
  border-top: 2px solid ${(props) => props.theme.palette.background.content};
  margin-bottom: ${rem(50)};
  padding-top: ${rem(50)};
`;

const Teams = styled(Row)`
  justify-content: center;
`;

const TeamsItem = styled(Col)`
  text-align: center;
  width: 48%;
  margin-bottom: ${(props) => props.theme.spacing.gutter};

  ${breakpoint('medium')} {
    width: 29%;
  }

  ${breakpoint('mediumlarge')} {
    width: 20%;
  }

  ${breakpoint('large')} {
    width: 17%;
  }

  ${breakpoint('xlarge')} {
    width: 14%;
  }
`;

const Sports = styled(Row)`
  justify-content: center;
  margin-top: ${rem(50)};
  text-align: center;
`;

const Leagues = styled(Row)`
  justify-content: center;
  margin-top: ${rem(50)};
  text-align: center;
`;

const Events = styled(Row)`
  justify-content: center;
`;

const Blurbs = styled.div`
  position: relative;
  margin-top: ${rem(60)};
  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};
  border-top: 2px solid ${(props) => props.theme.palette.background.content};
`;

const Blurb = styled.div`
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;
  text-align: center;
`;

const BlurbIcon = styled.div`
  display: block;
  margin: 0 auto 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.theme.palette.colors.cta};
  text-align: center;
`;

const BlurbCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

const Instagram = styled.div`
  position: relative;
  padding-bottom: ${rem(40)};
  text-align: center;

  ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  }
`;

const InstaCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: ${(props) => props.theme.spacing.gutter};
  width: 50%;

  ${breakpoint('mediumlarge')} {
    width: 25%;
  }

  ${breakpoint('large')} {
    width: 20%;
  }

  & img {
    width: 100%;
  }
`;

const Filter = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const FilterItem = styled.button`
  border: 0;
  display: inline-block;
  padding: 0.67em 1.35em;
  line-height: 1;
  margin: 0.3rem 0.35rem;
  font-size: 0.94rem;
  border-radius: 999px;
  background-color: ${(props) => props.theme.palette.background.content};
  color: #fff;
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};
  transition: all
    ${(props) =>
      `${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
  cursor: pointer;
  user-select: none;

  &.active {
    background-color: ${(props) => props.theme.palette.colors.cta};
  }

  /* ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  } */
`;

// const Testimonials = styled(Section)`
//   background-color: ${(props) => props.theme.palette.background.dark};
//   color: ${(props) => props.theme.palette.colors.white};
//   padding-top: ${rem(40)};
//   padding-bottom: ${rem(40)};
//   margin-bottom: 0 !important;

//   ${breakpoint('large')} {
//     padding-top: ${rem(70)};
//     padding-bottom: ${rem(70)};
//   }
// `;

// const TestimonialCol = styled(Col)`
//   margin: 0 0 ${rem(10)};
//   width: 100%;

//   blockquote {
//     font-size: ${rem(20)};
//   }

//   ${breakpoint('large')} {
//     width: 50%;
//   }
// `;

// const SectionHeader = styled(Col)`
//   text-align: center;
//   /* margin-top: ${rem(0)}; */
//   margin-bottom: ${rem(20)};
// `;

// const SectionFooter = styled(Col)`
//   text-align: center;
//   margin-top: ${rem(25)};
//   margin-bottom: ${rem(20)};
// `;

function Home({ data }) {
  const {
    allInstaNode: instaPosts,
    allTeamsJson: teams,
    allSportsJson: sports,
    allLeaguesJson: leagues,
    events,
  } = data;
  const { t } = useTranslation();
  const [filterSport, setFilterSport] = useState('all');

  const blurbs = [
    {
      id: 'blurb-1',
      title: t('blurb1Title'),
      text: t('blurb1Desc'),
    },
    {
      id: 'blurb-2',
      title: t('blurb2Title'),
      text: t('blurb2Desc'),
    },
    {
      id: 'blurb-3',
      title: t('blurb3Title'),
      text: t('blurb3Desc'),
    },
  ];

  const sportFilter = [
    {
      id: 'all',
      title: t('filterAll'),
      slug: 'all',
    },
  ];

  sports.edges.map(({ node: sport }) => {
    return sportFilter.push(sport);
  });

  const withInstagram = false;
  const withIntro = false;
  const withSports = false;
  // useLangRedirect(page);

  return (
    <Layout>
      <SEO title={t('frontSeoTitle')} />
      <Hero
        heading={t('frontHeading')}
        // image={featuredMedia?.localFile}
        // pageTitle
        preHeading={t('hashtag')}
        actions={
          <ScrollLink to="teamSelect" spy smooth duration={500}>
            <Button className="cta" as="div">
              {t('frontCtaButton')}
            </Button>
          </ScrollLink>
        }
        tint
        big
      >
        {t('frontHeroDesc')}
        <br />
        <span
          style={{ display: 'block', marginTop: '0.75em', fontSize: '1.25rem' }}
        >
          <strong>{t('frontHeroDescSub')}</strong>
        </span>
      </Hero>
      {events.edges.length > 0 && (
        <Section>
          <SectionHeader as={Row}>
            <Col>
              <h3>{t('selectEvent')}</h3>
            </Col>
          </SectionHeader>
          <Events>
            {events.edges.map(({ node: event }) => (
              <TeamsItem key={event.id}>
                <GridItem
                  link={`/${event.slug}/`}
                  image={event.logo}
                  title={event.title}
                />
              </TeamsItem>
            ))}
          </Events>
        </Section>
      )}
      <Section>
        <SectionHeader as={Row}>
          <Col>
            <h3>{t('selectLeague')}</h3>
          </Col>
        </SectionHeader>
        <Leagues>
          {leagues.edges.map(({ node: league }) => (
            <TeamsItem key={league.id}>
              <GridItem
                link={`/${league.sport}/${league.slug}/`}
                image={league.logo}
                title={league.title}
                subTitle={getTerm(league.sport, sports)?.title}
              />
            </TeamsItem>
          ))}
        </Leagues>
      </Section>
      <Element name="teamSelect">
        <Section>
          <SectionHeader as={Row}>
            <Col>
              <h3>{t('teamSectionTitle')}</h3>
              <p>{t('teamSectionDesc')}</p>
              <Filter>
                <h6>{t('filterOn')}</h6>
                {sportFilter.map((filterItem) => (
                  <FilterItem
                    key={filterItem.id}
                    sport={filterItem.slug}
                    onClick={() => setFilterSport(filterItem.slug)}
                    className={filterSport === filterItem.slug && 'active'}
                  >
                    {filterItem.title}
                  </FilterItem>
                ))}
              </Filter>
            </Col>
          </SectionHeader>
          <Teams>
            {teams.edges
              .filter(({ node: team }) => {
                return filterSport === 'all' || team.sport === filterSport;
              })
              .map(({ node: filteredTeam }) => {
                return (
                  <TeamsItem key={filteredTeam.id}>
                    <GridItem
                      link={`/${filteredTeam.slug}/`}
                      image={filteredTeam.logo}
                      title={filteredTeam.title}
                    />
                  </TeamsItem>
                );
              })}
            {/* teams.edges.map(({ node: team }) => (
              <TeamsItem key={team.id}>
                <GridItem
                  link={`/${team.slug}/`}
                  image={team.logo}
                  title={team.title}
                />
              </TeamsItem>
            )) */}
          </Teams>
          <SectionFooter as={Row}>
            <Col>
              <h4>{t('teamSectionFooterTitle')}</h4>
              <p>{t('teamSectionFooterDesc')}</p>
              <Button className="small" link="/kontakt/">
                {t('teamSectionFooterCta')}
              </Button>
            </Col>
          </SectionFooter>
        </Section>
      </Element>

      {withSports && (
        <Section>
          <SectionHeader as={Row}>
            <Col>
              <h3>{t('selectSport')}</h3>
            </Col>
          </SectionHeader>
          <Sports>
            {sports.edges.map(({ node: sport }) => (
              <TeamsItem key={sport.id}>
                <GridItem
                  link={`/${sport.slug}/`}
                  image={sport.image}
                  title={sport.title}
                />
              </TeamsItem>
            ))}
          </Sports>
        </Section>
      )}
      {withIntro && (
        <IntroSection>
          <Row>
            <IntroContent>
              <h2>{t('whoAreWe')}</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                condimentum scelerisque vulputate. Class aptent taciti sociosqu
                ad litora torquent per conubia nostra, per inceptos himenaeos.
                In hac habitasse platea dictumst. Sed ante lectus, accumsan id
                ipsum quis, congue viverra dui. Nam a sagittis dui. Aenean
                bibendum leo ut posuere tempor.
              </p>
            </IntroContent>
          </Row>
        </IntroSection>
      )}
      <Blurbs>
        <Container>
          <Row>
            {blurbs?.map((item) => {
              const { title, text } = item;
              return (
                <BlurbCol key={item.id}>
                  <Blurb>
                    <BlurbIcon />
                    <h4>{title}</h4>
                    <p>{text}</p>
                  </Blurb>
                </BlurbCol>
              );
            })}
          </Row>
        </Container>
      </Blurbs>

      {withInstagram && (
        <Instagram>
          <SectionHeader>
            <Row>
              <Col>
                <h3>
                  {t('tagUs')}{' '}
                  <span style={{ color: colors.cta }}>
                    <strong>#minpublikfigur</strong>
                  </span>
                </h3>
              </Col>
            </Row>
          </SectionHeader>
          <Row>
            {instaPosts.edges?.map((item) => {
              const { id, localFile: image } = item.node;
              return (
                <InstaCol key={id}>
                  <Image
                    fluid={image?.childImageSharp.fluid}
                    fallback={{
                      url: image.src,
                      alt: id,
                    }}
                    alt={id}
                  />
                </InstaCol>
              );
            })}
          </Row>
        </Instagram>
      )}

      {/* testimonials.edges?.length && (
        <Testimonials>
          <Container>
            <Row>
              <Col>
                <SectionTitle>{t('saidAboutUs')}</SectionTitle>
              </Col>
            </Row>
            <Row>
              {testimonials.edges.map(({ node }) => (
                <TestimonialCol key={node.id}>
                  <TestimonialItem title={node.title}>
                    {node.content}
                  </TestimonialItem>
                </TestimonialCol>
              ))}
            </Row>
          </Container>
        </Testimonials>
              ) */}
    </Layout>
  );
}

export const query = graphql`
  query {
    allTeamsJson(filter: { league: { ne: "event" } }) {
      edges {
        node {
          id
          sport
          league
          slug
          title
          body
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    allLeaguesJson {
      edges {
        node {
          id
          title
          slug
          sport
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    allSportsJson {
      edges {
        node {
          id
          slug
          title
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    events: allTeamsJson(
      filter: { league: { eq: "event" }, active: { eq: true } }
    ) {
      edges {
        node {
          id
          sport
          league
          slug
          title
          body
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

// Add this when we posted something to the hashtag

// allInstaNode(limit: 10) {
//   edges {
//     node {
//       id
//       likes
//       comments
//       mediaType
//       preview
//       original
//       timestamp
//       caption
//       localFile {
//         childImageSharp {
//           fluid(maxWidth: 500, maxHeight: 500) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//       thumbnails {
//         src
//         config_width
//         config_height
//       }
//       dimensions {
//         height
//         width
//       }
//     }
//   }
// }

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Home;
